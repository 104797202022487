<template>
  <main class="login">
    <div class="login-container page-container container">
      <div class="login-box passwordSetup">
        <transition name="fade-up">
          <TemporaryPasswordAlert
            v-if="isTemporary && isTemporaryAlertDisplay"
            :isDisplay.sync="isTemporaryAlertDisplay"
          />
        </transition>
        <h3 class="login-title">{{ pageTitle }}</h3>
        <form class="login-form">
          <div class="form-group">
            <label class="login-form-label" for="user-password-old">{{
              oldPasswordLabel
            }}</label>
            <input
              class="form-control"
              type="password"
              id="user-password-old"
              v-model.trim="oldPassword"
              required
            />
          </div>
          <div class="form-group">
            <label class="login-form-label" for="user-password-new"
              >New password</label
            >
            <input
              class="form-control"
              type="password"
              id="user-password-new"
              v-model.trim="newPassword"
              required
            />
            <div
              class="login-form-tips"
              :class="{ 'text-danger': isTipsAddDanger }"
            >
              Passwords should contain at least 8 characters in length, at least
              1 upper case letter, 1 lower case letter, 1 number and 1 special
              character
            </div>
            <div
              class="invalid-feedback"
              style="display:block;"
              v-show="errorMsg.newPassword"
            >
              {{ errorMsg.newPassword }}
            </div>
          </div>
          <div class="form-group">
            <label class="login-form-label" for="user-password-confirm"
              >Confirm password</label
            >
            <input
              class="form-control"
              type="password"
              id="user-password-confirm"
              v-model.trim="confirmPassword"
              required
            />
            <div
              class="invalid-feedback"
              style="display:block;"
              v-show="errorMsg.confirmPassword"
            >
              {{ errorMsg.confirmPassword }}
            </div>
          </div>
          <div class="form-group login-form-reset" v-if="!isTemporary">
            <a class="btn btn-link" @click="$router.go(-1)">
              <span class="material-icons">chevron_left</span> go back</a
            >
          </div>
          <div class="form-group">
            <button
              type="submit"
              class="login-form-submit btn btn-primary"
              @click.prevent="submitPassword"
            >
              Change Password
            </button>
            <div
              class="invalid-feedback"
              style="display:block;"
              v-show="errorMsg.all"
            >
              {{ errorMsg.all }}
            </div>
          </div>
        </form>
      </div>
      <div class="login-bottomBg">
        <img :src="`${publicPath}img/login-bg.jpg`" alt="" />
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Validator from 'validator'
import TemporaryPasswordAlert from '../components/TemporaryPasswordAlert.vue'

export default {
  name: 'PasswordSetup',
  components: { TemporaryPasswordAlert },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      errorMsg: {
        all: '',
        newPassword: '',
        confirmPassword: ''
      },
      isTipsAddDanger: false,
      isTemporaryAlertDisplay: false
    }
  },
  mounted() {
    if (this.isTemporary) {
      this.isTemporaryAlertDisplay = true
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.self
    }),
    isTemporary() {
      return this.user.signedInWithTemporaryPassword
    },
    pageTitle() {
      return this.isTemporary ? 'Reset Password' : 'Change Password'
    },
    oldPasswordLabel() {
      return this.isTemporary ? 'Temporary password' : 'Original password'
    }
  },
  methods: {
    ...mapActions(['logout', 'user/PasswordSetup']),
    validateNewPassword() {
      this.errorMsg.newPassword = ''
      this.isTipsAddDanger = false

      if (this.oldPassword === '' && this.newPassword === '') return false

      let isSameWithOldPassword = this.isSamePassword(
        this.oldPassword,
        this.newPassword
      )
      if (isSameWithOldPassword) {
        this.errorMsg.newPassword =
          'The new password and original password is the same'
        return false
      }

      let isStrongPassword = Validator.isStrongPassword(this.newPassword)
      if (!isStrongPassword) {
        this.isTipsAddDanger = true
        return false
      }
      return true
    },
    validateConfirmPassword() {
      this.errorMsg.confirmPassword = ''

      if (this.newPassword === '' || this.confirmPassword === '') return false

      let isSameWithNewPassword = this.isSamePassword(
        this.newPassword,
        this.confirmPassword
      )
      if (!isSameWithNewPassword) {
        this.errorMsg.confirmPassword =
          'This password with new password is not the same'
        return false
      }

      return true
    },
    isSamePassword(password1, password2) {
      return password1 === password2
    },
    formValidation() {
      let validateNewPassword = this.validateNewPassword()
      let validateConfirmPassword = this.validateConfirmPassword()
      console.log(validateNewPassword)
      return (
        this.oldPassword &&
        this.newPassword &&
        this.confirmPassword &&
        validateNewPassword &&
        validateConfirmPassword
      )
    },
    async submitPassword() {
      this.errorMsg.all = ''
      let formValidation = this.formValidation()
      console.log('form validate : ' + formValidation)
      if (!formValidation) {
        this.errorMsg.all = 'please check your password....'
        return
      }
      const password = {
        originalPassword: this.oldPassword,
        password: this.newPassword
      }
      let status = await this['user/PasswordSetup'](password)
      if (status === 'success') {
        this.logoutHandler()
        // this.$router.push('/connections')
      } else {
        this.errorMsg.all = 'please check your password....'
      }
    },
    async logoutHandler() {
      let status = await this.logout()
      if (status === 200) {
        // this.$router.push('/pages/login')
        return window.location.reload()
      } else {
        return this.$router.push('/pages/page404')
      }
    },
    closeTemporaryAlert() {
      this.isTemporaryAlertDisplay = false
    }
  }
}
</script>
