<template>
  <div class="alert alert-primary alert-dismissible">
    <strong class="alert-heading">Welcome to Bonder</strong>
    <p>
      For your security reason, please kindly change your password after first
      login.
    </p>
    <button
      type="button"
      class="close"
      aria-label="Close"
      @click.prevent="closeAlert"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>
<script>
export default {
  name: 'TemporaryPasswordAlert',
  props: {
    isDisplay: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeAlert() {
      this.$emit('update:isDisplay', false)
    }
  }
}
</script>
